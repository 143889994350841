<template>
   <v-app class="admin-panel">
      <router-view></router-view>
   </v-app>
</template>

<script>
import mutations from '../../store/mutation-types';
export default {
   name: 'AdminPanel',

   provide() {
      return {
         checkIfErrorIsTokenNotFound: this.checkIfErrorIsTokenNotFound,
         loadResources: this.loadResources,
      };
   },

   computed: {
      resources() {
         return this.$store.getter['admin/getResources'];
      },

      routeName() {
         return this.$route.params.name;
      },
   },

   methods: {
      checkIfErrorIsTokenNotFound(error) {
         if (error.data.message.match('jwt')) {
            sessionStorage.isAuth = false;
            this.$router.push({ name: 'admin-login' });
         } else if (error.data.message) {
            alert(error.data.message);
         }
      },

      async loadResources() {
         try {
            const response = await this.$http.post('/admin/resources');
            this.$store.commit(`admin/${mutations.UPDATE_ADMIN_RESOURCES}`, response.data.resources);
            this.$store.commit(`admin/${mutations.UPDATE_ADMIN_SETTINGS}`, response.data.settings);
            this.$store.commit(`admin/${mutations.UPDATE_LOADING}`, false);
         } catch (error) {
            this.checkIfErrorIsTokenNotFound(error);
         }
      },
   },
};
</script>

<style lang="scss">
.v-application p {
   margin: 0 !important;
}

.admin-panel {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   & * {
      box-sizing: border-box;
   }
}
</style>